import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = (process.env.NODE_ENV === 'development' ? true : false)
window.Stimulus   = application

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

export { application }
