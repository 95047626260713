import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="visibility"
// Targets data-visibility-target="group"
export default class extends Controller {
  static targets = ["group"]
  
  select(event) {
    let group = event.currentTarget.dataset.groupId

    this.groupTargets.forEach((elem) => {
      if (elem.dataset.groupId == group) {
        // show
        elem.classList.remove("d-none")
      } else {
        // hide
        elem.classList.add("d-none")
      }
    })
  }
}
