import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout--credit-card"
export default class extends Controller {
  static targets = [
    "creditCardNumberField", "creditCardCodeField", "creditCardYearField", "creditCardMonthField", "creditCardDataField"
  ]

  static values = {
    disabled: { type: Boolean, default: false},
    lastCreditCardId: Number,
    authorizeApiId: String,
    authorizeApiKey: String,
  }

  get authData() {
    return {
      'apiLoginID': this.authorizeApiIdValue,
      'clientKey': this.authorizeApiKeyValue,
    }
  }

  get cardData() {
    return {
      'cardNumber': this.creditCardNumberFieldTarget.value.replace(/[^0-9]/g, ''),
      'month': this.creditCardMonthFieldTarget.value.replace(/[^0-9]/g, ''),
      'year': this.creditCardYearFieldTarget.value.replace(/[^0-9]/g, ''),
      'cardCode': this.creditCardCodeFieldTarget.value.replace(/[^0-9]/g, ''),
    }
  }

  setFieldsForSubmit() {
    this.creditCardNumberFieldTarget.disabled = true
    this.creditCardMonthFieldTarget.disabled = true
    this.creditCardYearFieldTarget.disabled = true
    this.creditCardCodeFieldTarget.disabled = true

    this.creditCardNumberFieldTarget.setAttribute('data-fields--validation-invalid-value', 'false')
    this.creditCardMonthFieldTarget.setAttribute('data-fields--validation-invalid-value', 'false')
    this.creditCardYearFieldTarget.setAttribute('data-fields--validation-invalid-value', 'false')
    this.creditCardCodeFieldTarget.setAttribute('data-fields--validation-invalid-value', 'false')
  }

  releaseFieldsForSubmit() {
    this.creditCardNumberFieldTarget.disabled = false
    this.creditCardMonthFieldTarget.disabled = false
    this.creditCardYearFieldTarget.disabled = false
    this.creditCardCodeFieldTarget.disabled = false
  }

  // requireCVV() {
  //   if (this.creditCardCodeFieldTarget.value.trim() == '') {
  //     this.creditCardCodeFieldTarget.setAttribute('data-fields--validation-invalid-value', 'true')
  //     this.creditCardCodeFieldTarget.setAttribute('data-fields--validation-message-value', 'CVV code must be entered.')
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  // get tokenized CC info from Authorize.net
  tokenize() {
    var dispatch, timeout;

    // wrap the request to Authorize.net in a promise
    dispatch = new Promise((resolve, reject) => {
      var secureData;
      secureData = {};
      secureData.authData = this.authData;
      secureData.cardData = this.cardData;

      // if (!this.requireCVV()) return reject('CVV code must be entered.');

      // console.log('getting cc token', secureData)
      return Accept.dispatchData(secureData, (res) => {
        var data, i, len, message, ref;
        // console.log(res)
        if (res.messages.resultCode === 'Ok') {
          data = {
            descriptor: res.opaqueData.dataDescriptor,
            value: res.opaqueData.dataValue
          };
          this.creditCardDataFieldTarget.value = data.value;
          return resolve(data);
        } else {
          ref = res.messages.message;
          for (i = 0, len = ref.length; i < len; i++) {
            message = ref[i];
            if (message.code === "E_WC_05") {
              this.creditCardNumberFieldTarget.setAttribute('data-fields--validation-invalid-value', 'true')
              this.creditCardNumberFieldTarget.setAttribute('data-fields--validation-message-value', message.text)
            }
            if (message.code === "E_WC_06" || message.code === "E_WC_08") {
              this.creditCardMonthFieldTarget.setAttribute('data-fields--validation-invalid-value', 'true')
              this.creditCardMonthFieldTarget.setAttribute('data-fields--validation-message-value', message.text)
            }
            if (message.code === "E_WC_07" || message.code === "E_WC_08") {
              this.creditCardYearFieldTarget.setAttribute('data-fields--validation-invalid-value', 'true')
              this.creditCardYearFieldTarget.setAttribute('data-fields--validation-message-value', message.text)
            }
            if (message.code === "E_WC_15") {
              this.creditCardCodeFieldTarget.setAttribute('data-fields--validation-invalid-value', 'true')
              this.creditCardCodeFieldTarget.setAttribute('data-fields--validation-message-value', message.text)
            }
          }
          return reject(res.messages.message);
        }
      });
    });

    // create a second promise that is guranteed to return after 20s
    timeout = new Promise((resolve, reject) => {
      var id;
      return id = setTimeout(function () {
        clearTimeout(id);
        return reject('The request to the credit card processor timed out. Please try again, or contact us for help.');
      }, 20000);
    });
    // return the promise that finishes first.
    // We do this because if authorize.net is down they will never execute our anonymous function and the promise will never return.
    return Promise.race([dispatch, timeout]);
  };
}
