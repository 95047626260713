import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout--payment"
export default class extends Controller {
  static targets = [ "creditCardForm", "submitButton" ]

  get creditCardController() {
    return this.application.getControllerForElementAndIdentifier(this.creditCardFormTarget, 'checkout--credit-card')
  }

  // connect() {
  //   this.element.addEventListener('turbo:before-fetch-response', event => {
  //     console.log(event)
  //     console.log(event.details)
  //   })
  // }

  placeOrder(event) {
    event.preventDefault()

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTargets.forEach( button => {
        this.application.getControllerForElementAndIdentifier(button, 'forms--submit').submitting()
      })
    }

    // if credit card is used
    if (this.hasCreditCardFormTarget) {
      // get the tokenized credit card from Authorize
      this.creditCardController.setFieldsForSubmit()
      this.creditCardController.tokenize()

      .then((res) => {
        // we have the CC token
        // console.log(res)
        this.element.requestSubmit()
      })
      .catch((errors) => {
        let messages

        // console.log('errors:', errors)
        
        if (typeof errors === 'string') {
          messages = [errors]
        } else {
          messages = errors.map((err) => err.text)
        }

        alert(messages.join("\n"))

        this.creditCardController.releaseFieldsForSubmit()

        if (this.hasSubmitButtonTarget) {
          this.submitButtonTargets.forEach(button => {
            this.application.getControllerForElementAndIdentifier(button, 'forms--submit').completed()
          })
        }
      })
    } else {
      this.element.requestSubmit()
    }
  }
}
