import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="option-button"
export default class extends Controller {
  static targets = ["option"]
  static values = {
    variantId: Number,
  }

  connect() {
    this.variantIdValueChanged()
  }

  choose(event) {
    this.variantIdValue = event.params.variantId
  }

  variantIdValueChanged() {
    this.optionTargets.forEach(option => {
      if (option.dataset.optionButtonVariantIdParam == this.variantIdValue) {
        option.classList.add('active')
      } else {
        option.classList.remove('active')
      }
    });
    this.dispatch("update", { detail: { content: this.variantIdValue } })
  }
}
