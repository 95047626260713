import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input"
import debounce from "lodash/debounce"

// Connects to data-controller="fields--phone"
export default class extends Controller {
  static targets = ["input"]

  // implement this way for older safari; if this code will be sent directly to browser without preprocessing
  // https://caniuse.com/mdn-javascript_classes_public_class_fields
  // https://github.com/hotwired/stimulus/issues/366
  // static get targets() {
  //   return [ "input" ]
  // }

  connect() {
    this.updatePhone = debounce(this.updatePhone, 200)
    this.updatePhone()

    this.intlTelInput = intlTelInput(this.inputTarget, {
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.min.js",
      hiddenInput: "phone",
      initialCountry: "us",
      preferredCountries: ["us", "ca", "gb"],
      separateDialCode: true,
      autoPlaceholder: "aggressive"
    })

    // this.intlTelInput.hiddenInput.addEventListener("change", this.updatePhone)
    this.intlTelInput.hiddenInput.addEventListener("change", this.updatePhone)
    this.inputTarget.addEventListener("countrychange", () => { this.updatePhone() })
  }

  updatePhone() {
    if (this.intlTelInput.isValidNumber()) {
      this.intlTelInput.setNumber(this.intlTelInput.getNumber())
    }
  }
}
