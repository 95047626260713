import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="forms--submit"
export default class extends Controller {
  static values = {
    formId: String,
  }

  get form() {
    if (this.hasFormIdValue) {
      return document.getElementById(this.formIdValue)
    } else {
      return this.element.closest('form')
    }
  }

  connect() {
    this.form.addEventListener('turbo:submit-start', () => {
      this.submitting()
    })

    this.form.addEventListener('turbo:submit-end', () => {
      this.completed()
    })
  }

  submitting() {
    this.element.disabled = true
    this.element.classList.add('submitting')
  }

  completed() {
    this.element.disabled = false
    this.element.classList.remove('submitting')
  }
}
