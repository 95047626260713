import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fields--validation"
export default class extends Controller {
  static values = {
    invalid: { type: Boolean, default: false },
    message: { type: String, default: '' },
  }

  connect() {
    this.messageTarget = document.createElement('div')
    this.messageTarget.innerText = this.messageValue
    this.messageTarget.classList.add('invalid-feedback')
    this.element.insertAdjacentElement('afterend', this.messageTarget)
  }

  invalidValueChanged(val) {
    if (val) {
      this.element.classList.add('is-invalid')
    } else {
      this.element.classList.remove('is-invalid')
    }
  }

  messageValueChanged(val) {
    if (this.messageTarget) this.messageTarget.innerHTML = val
  }
}
