import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link-append"
export default class extends Controller {
  static values = {
    append: String
  }

  connect() {
    for (let elem of this.element.getElementsByTagName("a")) {
      let path = elem.getAttribute('href')
      elem.setAttribute('href', this.new_path(path))
      elem.setAttribute('rel', 'nofollow')
    }
  }

  new_path(path) {
    if (path.includes("?")) {
      return path + "&" + this.appendValue
    } else {
      return path + "?" + this.appendValue
    }
  }
}
