import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ga--product-list"
export default class extends Controller {
  static values = {
    id: String,
    slug: String
  }

  static targets = ['product']

  connect() {
    if (this.hasProductTarget && typeof window.gtag === "function") {
      gtag('event', 'view_item_list', {
        item_list_id: this.idValue,
        item_list_name: this.slugValue,
        items: this.item_list,
      })
    }
  }

  get item_list() {
    let items = this.productTargets.map((prod, i) => {
      return {
        item_id: prod.dataset.productId,
        item_name: prod.dataset.productName,
        index: i + 1,
      }
    })
    return items
  }
}
