import { Controller } from "@hotwired/stimulus"
import Axios from 'axios'

// Connects to data-controller="add-to-cart"
export default class extends Controller {
  static targets = ["product", "button"]
  static values = {
    url: String,
    promoId: Number,
    productId: Number,
  }

  connect() {
    this.setButtonStatus()
  }

  add() {
    Axios.post(`/cart/add/${this.productIdValue}`, {
      promo: this.promoIdValue,
      items: this.items,
    }, {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      }
    }).then(() => location.reload())
      .catch(() => alert('Sorry, we couldn\'t add that item to your cart. Please try again or contact us for help.'))
  }

  update() {
    this.setButtonStatus()
  }

  setButtonStatus() {
    this.buttonTargets.forEach(element => {
      // console.log(element, this.readyToAdd)
      element.disabled = this.disabled
    });
  }

  get disabled() {
    return !this.readyToAdd
  }

  get readyToAdd() {
    let ready = false

    this.productTargets.forEach(element => {
      let controller = this.application.getControllerForElementAndIdentifier(element, 'option-button')
      // if (controller != null) console.log(controller.variantIdValue)

      if (controller != null && controller.variantIdValue != null && controller.variantIdValue != 0) {
        ready = true
      }
    });

    return ready
  }

  get items() {
    let items = {}

    this.productTargets.forEach(element => {
      let controller = this.application.getControllerForElementAndIdentifier(element, 'option-button')
      
      if (controller != null) {
        items[controller.variantIdValue.toString()] = {
          qty: 1,
          id: controller.variantIdValue.toString()
        }
      }
    })

    return items
  }
}
