import { Controller } from "@hotwired/stimulus"
import Cleave from 'cleave.js'

// Connects to data-controller="checkout--credit-card-icon"
export default class extends Controller {
  static targets = ["number", "icon"]
  
  connect() {
    // setup credit card number field formater
    this.cleave = new Cleave(this.numberTarget, {
      creditCard: true,
      // when a card is detected, change the icon to match
      onCreditCardTypeChanged: (type) => {
        this.iconTarget.classList.forEach((val) => this.iconTarget.classList.remove(val))
        if (type == 'amex') this.iconTarget.classList.add('icon-cc-amex')
        if (type == 'mastercard') this.iconTarget.classList.add('icon-cc-mastercard')
        if (type == 'visa') this.iconTarget.classList.add('icon-cc-visa')
        if (type == 'discover') this.iconTarget.classList.add('icon-cc-discover')
      }
    })
  }
}
