import { Controller } from "@hotwired/stimulus"

// Adapted from:
// https://github.com/excid3/tailwindcss-stimulus-components/blob/537cd5dd6ed28a9ef079163949bb5936c8f0265a/src/tabs.js

// Connects to data-controller="panel"
export default class extends Controller {
  static targets = ["button", "menu", "panel", "back"]

  connect() {
    // if (this.anchor) this.index = this.buttonTargets.findIndex((button) => button.id === this.anchor)
  }

  showPanel() {
    this.menuTargets.forEach((menu) => {
      menu.classList.add('d-none')
    })

    this.backTargets.forEach((menu) => {
      menu.classList.remove('d-none')
    })

    this.buttonTargets.forEach((button, index) => {
      const panel = this.panelTargets[index]

      if (index === this.index) {
        panel.classList.remove('d-none')
    
        // Update URL with the tab ID if it has one
        // This will be automatically selected on page load
        // if (button.id) {
        //   location.hash = button.id
        // }
      } else {
        panel.classList.add('d-none')
      }
    })
  }

  showMenu(event) {
    event.preventDefault()

    this.menuTargets.forEach((menu) => {
      menu.classList.remove('d-none')
    })

    this.backTargets.forEach((back) => {
      back.classList.add('d-none')
    })

    this.buttonTargets.forEach((button) => {
      button.classList.remove('d-none')
    })

    this.panelTargets.forEach((panel) => {
      panel.classList.add('d-none')
    })
  }

  change(event) {
    event.preventDefault()

    this.index = this.buttonTargets.indexOf(event.currentTarget)
  }

  get index() {
    return parseInt(this.data.get('index') || 0)
  }

  set index(value) {
    this.data.set('index', (value >= 0 ? value : 0))
    this.showPanel()
  }

  get anchor() {
    return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  }

}
