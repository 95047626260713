import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    show: Boolean,
    removeOnClose: Boolean,
    reposition: Boolean,
  }

  connect() {
    // hide any existing modals
    $('.modal.show').modal('hide')

    if (this.repositionValue) {
      // move this element to the body if it isn't already there
      if (!$(this.element).parent().is('body')) {
        $(this.element).appendTo('body')
        return
      }
    }

    // set this controller element to a modal
    $(this.element).modal({
      show: this.showValue
    })

    // if removeOnClose is set to true
    if (this.removeOnCloseValue) {
      // watch for the hidden event
      $(this.element).on('hidden.bs.modal', (e) => {
        // delete this controller from the DOM
        $(this.element).remove()
      })
    }
  }

  // these haven't been used or tested
  // show() {
  //   this.element.modal('show')
  // }

  // hide() {
  //   this.element.modal('hide')
  // }
}
