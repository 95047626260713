import { Controller } from "@hotwired/stimulus"
import emailSpellChecker from '@zootools/email-spell-checker'

// Connects to data-controller="emailcheck"
export default class extends Controller {
  static targets = ["suggestion", "field"]
  
  suggest(event) {
    let suggestedEmail = emailSpellChecker.run({
      email: event.target.value
    })

    if (suggestedEmail) {
      this.updateSugestion(suggestedEmail.full)
    } else {
      this.updateSugestion()
    }
  }

  update(event) {
    this.fieldTarget.value = event.params.email
    this.updateSugestion()
  }

  updateSugestion(email) {
    if (this.hasSuggestionTarget) {
      this.suggestionTarget.innerHTML = this.suggestionTemplate(email)
    }
  }

  suggestionTemplate(email) {
    if (email) {
      return `Did you mean? <a href="#" data-action="emailcheck#update:prevent" data-emailcheck-email-param="${email}">${email}</a>`
    } else {
      return ''
    }
  }
}

// ruby to get most popular domains from database
// domains = Hash.new { | hash, key | hash[key] = 0 }
// Customer.pluck(:email).map{| e | e.split('@')[-1] }.each do | domain |
// 	domains[domain] += 1
// end; 1
// domains.sort{| a, b | b[1] <=> a[1] } [0, 10]
