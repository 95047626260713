import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="forms--disable"
export default class extends Controller {
  connect() {
    this.element.closest('form').addEventListener('submit', () => {
      this.element.disabled = true
    })

    this.element.closest('form').addEventListener('turbo:submit-end', () => {
      this.element.disabled = false
    })
  }
}
