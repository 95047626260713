import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="focus"
export default class extends Controller {
  connect() {
  }

  click(event) {
    document.getElementById(event.params.id).focus()
  }
}
