import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ga--add-to-cart"
export default class extends Controller {
  static values = {
    requestId: String,
    id: Number,
    name: String,
    price: Number,
    sku: String,
    qty: Number,
  }

  connect() {
    if (this.hasIdValue && this.hasNameValue && this.hasPriceValue && this.hasSkuValue && this.hasQtyValue) {
      gtag('event', 'add_to_cart', {
        currency: 'USD',
        value: this.qtyValue * this.priceValue,
        items: [
          {
            item_id: this.idValue,
            item_name: this.nameValue,
            item_variant: this.skuValue,
            price: this.priceValue,
            quantity: this.qtyValue
          }
        ]
      });

      gtag('event', 'button_click', {
        button: 'add to cart'
      });
    }
  }
}
