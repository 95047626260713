import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="forms--address-autocomplete"
export default class extends Controller {
  static values = {
    key: String,
    session: String,
  }

  initialize() {
    let id = "forms--address-autocomplete-script-tag"

    window.initAutoComplete = this.initAutoComplete

    if (document.getElementById(id) == null) {
      this.scriptTag = document.createElement('script')
      this.scriptTag.id = id
      this.scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${this.keyValue}&sessiontoken=${this.sessionValue}&libraries=places&callback=initAutoComplete`
      this.scriptTag.async = true
      document.head.appendChild(this.scriptTag);
    } else {
      this.initAutoComplete()
    }
  }

  // create a callback for initializing the Google Maps API
  initAutoComplete() {
    var autocomplete, input, options, parsePlaceChange, updateAddress;
    input = document.getElementById("address_address");
    options = {
      types: ["address"],
      fields: ["address_components"]
    };
    autocomplete = new google.maps.places.Autocomplete(input, options);
    updateAddress = function (address, city, region, country, postal_code) {
      $("#address_address").val(address);
      $("#address_city").val(city);
      $("#address_country").val(country);
      $("#address_country").change();
      $("#address_postal_code").val(postal_code);
      return $("#address_region").val(region);
    };
    parsePlaceChange = function () {
      var address, city, component, country, i, len, place, postal_code, ref, region;
      place = autocomplete.getPlace();
      // console.log place.address_components
      address = '';
      city = '';
      region = '';
      country = '';
      postal_code = '';
      ref = place.address_components;
      for (i = 0, len = ref.length; i < len; i++) {
        component = ref[i];
        (function (component) {
          if (component.types.indexOf('street_number') !== -1) {
            address = address + component.short_name + ' ';
          }
          if (component.types.indexOf('route') !== -1) {
            address = address + component.short_name;
          }
          if (component.types.indexOf('locality') !== -1) {
            city = component.short_name;
          }
          if (component.types.indexOf('administrative_area_level_1') !== -1) {
            region = component.short_name;
          }
          if (component.types.indexOf('country') !== -1) {
            country = component.short_name;
          }
          if (component.types.indexOf('postal_code') !== -1) {
            return postal_code = component.short_name;
          }
        })(component);
      }
      // console.log(address, city, region, country, postal_code)
      return updateAddress(address, city, region, country, postal_code);
    };
    return autocomplete.addListener('place_changed', parsePlaceChange);
  }

}
