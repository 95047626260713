import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide"
// data-show-hide-hide-value=true || false
// data-show-hide-target="hidable"
// data-action="click->show-hide#toggle:stop"
export default class extends Controller {
  static targets = ["hidable"]
  static values = {
    hide: Boolean
  }

  connect() {
    if (this.hideValue) this.hideValueChanged(true)
  }

  hideValueChanged(hide) {
    this.hidableTargets.forEach((element) => {
      this.handleElement(element, hide)
    })
  }

  hidableTargetConnected(element) {
    this.handleElement(element, this.hideValue)
  }

  hide() {
    this.hideValue = true
  }

  show() {
    this.hideValue = false
  }

  toggle() {
    this.hideValue = !this.hideValue
  }

  handleElement(element, hide) {
    if (hide) {
      this.hideElement(element)
    } else {
      this.showElement(element)
    }
  }

  hideElement(element) {
    element.classList.add("d-none")
  }

  showElement(element) {
    element.classList.remove("d-none")
  }
}
