import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ['source', 'button']

  copy(event) {
    event.preventDefault()
    
    // copy the text to the clipboard
    navigator.clipboard.writeText(this.sourceTarget.value)
    
    // get old title from bootstrap attr
    let original_title = $(this.buttonTarget).attr('data-original-title')

    // change the title to copied
    $(this.buttonTarget).attr('data-original-title', 'Copied!').tooltip('show')
    
    // when it is hidden
    $(this.buttonTarget).on('hidden.bs.tooltip', () => {
      // reset the title back to the original title
      $(this.buttonTarget).attr('data-original-title', original_title)
    })
  }
}
