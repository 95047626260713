import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="click"
export default class extends Controller {
  static targets = ["click"]

  click() {
    this.clickTargets.forEach(target => target.click())
  }
}
